import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getAdminData, getCurrentUser, handleLoginAdmin, isAdmin } from "../../utils/auth"
import { CreditCard, Repeat, XCircle } from "react-feather"
import Moment from 'react-moment';
import FileSaver from 'file-saver';
import Modal from "react-bootstrap/Modal"
import AdminDeactivateRecipient from "../AdminDeactivateRecipient"
import Button from "react-bootstrap/Button"
import { USER_DONOR } from "../../utils/constants"
import Card from "react-bootstrap/Card"
import { paymentHttpColor } from "../../utils/misc"
import tippy from "tippy.js"
const axios = require('axios').default;
const classNames = require('classnames');

const _ = require('lodash');

const PurchaseList = ({ purchaseList, userType, isAdmin=false }) => {


  const fetchPurchases = (dataCallback, usertype) => {
    const userdata = getCurrentUser();
    const options = {
      method: "post",
      url: `${api_base_url()}/${userdata.cookie}/payments`,
      data: {
        BrowserData: browserstring(),
        Limit: 10,
        userType: usertype,
      },
    }

    axios(options)
      .then(response => {
        if (response.status === 204) {
          dataCallback([])
        } else {
          dataCallback(response.data)
        }
      })
  }

  const formatIsoTimestamp = (isoTimestamp) => {
    let ts_date = Date.parse(isoTimestamp);
    return new Intl.DateTimeFormat('sv-SE', { dateStyle: 'long', timeStyle: 'short' }).format(ts_date);
  }

  const userdata = getCurrentUser()
  const downloadList = () => {
    let browser_data = browserstring();

    const options = {
      method: "get",
      // TODO!!! Inte hårdkoda!
      url: `${api_base_url()}/${userdata.cookie}/payments?userType=${userType}&BrowserData=${browser_data}&outputFormat=csv&limit=5000`,
    }


    axios(options)
      .then(response => {
        const csvData = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

        FileSaver.saveAs(csvData, 'purchases.csv');
      });

  }

  if (purchaseList == null) {
    return (<div>Laddar köp...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  }

  let rows = [];
  let hasUnpaidRows = false
  for (let i = 0; i < purchaseList.length; i++) {
      console.log("-------------- ETT KÖP -----------");
      console.log(purchaseList[i]);
    if (purchaseList[i].status !== 'paid') {
      hasUnpaidRows = true
    }

    rows.push(
      <tr key={purchaseList[i].timestamp} className={styles.containerTableRow}>

        <td className={styles.containerTableCell}>
          <Moment format="YYYY-MM-DD HH:mm" date={purchaseList[i].timestamp} />
        </td>
        <td className={styles.containerTableCell}>
          {purchaseList[i].productName}
        </td>
        <td className={styles.containerTableCell}>
          {purchaseList[i].quantity}
        </td>
        <td className={styles.containerTableCell}>
          {purchaseList[i].amount} {purchaseList[i].currency === 'SEK' ? 'kr' : purchaseList[i].currency}
        </td>
        {userType !== USER_DONOR && <td className={styles.containerTableCell}>
          {purchaseList[i].user}
        </td>}
        <td className={styles.containerTableCell}>
          {purchaseList[i].uid}
        </td>

        <td className={styles.containerTableCell}>
          <CreditCard color={paymentHttpColor(purchaseList[i].status)} size={24} />
        </td>
      </tr>
    );
  }

  return (<>
    <Card>
    <Card.Body style={{padding: "0"}}>
            <div className={[styles.containerBody].join(" ")}>
              <h6 style={{display: hasUnpaidRows ? "block" : "none"}}>
                Gul statusmarkering = köpet genomfört helt eller delvis på kredit.
                Röd statusmarkering = köpet avbrutet.
              </h6>
              {rows.length === 0 ? (
                  <div>Inga köp att visa</div>
                ):null}
              {rows.length > 0 ? (
              <table className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

                <thead className={["thead-light", styles.containerTableHead].join(" ")}>
                <tr className={styles.containerTableHeadRow}>
                  <th className={styles.containerTableCell}>Köpdatum</th>
                  <th className={styles.containerTableCell}>Produkt</th>
                  <th className={styles.containerTableCell}>Antal</th>
                  <th className={styles.containerTableCell}>Belopp</th>
                  {userType !== USER_DONOR && <th className={styles.containerTableCell}>Kund</th>}
                  <th className={styles.containerTableCell}>Id för köp</th>
                  <th className={styles.containerTableCell}>Status</th>
                </tr>
                </thead>
                <tbody className={styles.containerTableBody}>
                {rows}
                </tbody>

              </table>
              ):null}
            </div>

      <div className={classNames("mb-2", "mt-2")}>
        <Button variant="success" onClick={() => { downloadList() }} className={classNames(styles.downloadButton, "float-right", "mr-3")} >Nedladdning av lista med genomförda köp</Button>
      </div>
    </Card.Body>
    </Card>

    </>
  )
}

PurchaseList.propTypes = {
  purchaseList: PropTypes.array,
}

PurchaseList.defaultProps = {
  purchaseList: [],
}

export default PurchaseList