import React from "react"
import View from "./View"
import { getCurrentUser } from "../utils/auth"

const Details = () => {
  const { username, cookie } = getCurrentUser()

  return (
    <View title="Your Details">
      <p>
        This is a client-only route. You can get additional information about a
        user on the client from this page.
      </p>
      <ul>
        <li>Username: {username}</li>
        <li>Cookie: {cookie}</li>
      </ul>
    </View>
  )
}

export default Details
