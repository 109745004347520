import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getAdminData, getCurrentUser, handleLoginAdmin, isAdmin } from "../../utils/auth"
import { CreditCard, Repeat, XCircle } from "react-feather"
import Moment from 'react-moment';
import FileSaver from 'file-saver';
import Modal from "react-bootstrap/Modal"
import AdminDeactivateRecipient from "../AdminDeactivateRecipient"
import Button from "react-bootstrap/Button"
import { USER_DONOR, USER_SUBSCRIPTION_RECIPIENT } from "../../utils/constants"
import Card from "react-bootstrap/Card"
import { paymentHttpColor } from "../../utils/misc"
import tippy from "tippy.js"
const axios = require('axios').default;
const classNames = require('classnames');

const _ = require('lodash');

const SubscriptionsList = ({ subscriptionsList, userType, dataUpdateCallback, isAdmin=false }) => {

  const [show, setShow] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);

  useEffect(() => {
    updateRecurringButtonsTooltips();
  }, [subscriptionsList])

  const fetchSubscriptions = (dataCallback, usertype) => {
    const userdata = getCurrentUser();
    const options = {
      method: "post",
      url: `${api_base_url()}/${userdata.cookie}/subscriptions`,
      data: {
        BrowserData: browserstring(),
        Limit: 10,
        userType: usertype,
      },
    }

    axios(options)
      .then(response => {
        if (response.status === 204) {
          dataCallback([])
        } else {
          dataCallback(response.data)
        }
      })
  }


  const updateRecurringButtonsTooltips = () => {
    let textContent = "";
    let textContentNoRepeat = "Prenumerationen förnyas inte automatiskt.";
    if (userType === USER_SUBSCRIPTION_RECIPIENT) {
      textContent = "Prenumerationen förnyas automatiskt.";
    } else {
      textContent = "Prenumerationen förnyas automatiskt. Klicka här för att avsluta.";
    }

    let allInstances = tippy(document.getElementsByName('RepeatSub' + (userType === USER_SUBSCRIPTION_RECIPIENT ? "Rec": "")));

    for (const instance of allInstances) {
      instance.setContent(textContent);
    }

    let allNoRepeatInstances = tippy(document.getElementsByName('NoRepeatSub' + (userType === USER_SUBSCRIPTION_RECIPIENT ? "Rec": "")));

    for (const instance of allNoRepeatInstances) {
      instance.setContent(textContentNoRepeat);
    }
  }

  const handleCancelSub = () => {
    const userdata = getCurrentUser();

    const options = {
      method: "post",
      url: `${api_base_url()}/${userdata.cookie}/subscriptions/cancel`,
      data: {
        BrowserData: browserstring(),
        siteId: selectedSub.siteid,
        timestamp: selectedSub.timestamp,
      },
    }

    axios(options)
      .then(response => {
        if (response.status === 200) {
          fetchSubscriptions(dataUpdateCallback, userType)
        }

        setSelectedSub(null)
        setShow(false);
      })
  }
  const handleClose = () => {
    setSelectedSub(null);
    setShow(false);
  }
  const handleShow = (e) => {
    setSelectedSub(e.currentTarget.dataset);
    setShow(true);
  }

  const formatIsoTimestamp = (isoTimestamp) => {
    let ts_date = Date.parse(isoTimestamp);
    return new Intl.DateTimeFormat('sv-SE', { dateStyle: 'long', timeStyle: 'short' }).format(ts_date);
  }

  const userdata = getCurrentUser()
  const downloadList = () => {
    let browser_data = browserstring();

    const options = {
      method: "get",
      // TODO!!! Inte hårdkoda!
      url: `${api_base_url()}/${userdata.cookie}/subscriptions?userType=${userType}&BrowserData=${browser_data}&outputFormat=csv&limit=5000`,
    }


    axios(options)
      .then(response => {
        const csvData = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

        FileSaver.saveAs(csvData, 'subscriptions.csv');
      });

  }

  if (subscriptionsList == null) {
    return (<div>Laddar prenumerationer...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  }

  let rows = [];
  let hasUnpaidRows = false
  for (let i = 0; i < subscriptionsList.length; i++) {
    if (subscriptionsList[i].status !== 'paid') {
      hasUnpaidRows = true
    }

    rows.push(
      <tr key={subscriptionsList[i].timestamp} className={styles.containerTableRow}>

        <td className={styles.containerTableCell}>
          {subscriptionsList[i].pageUrl}
        </td>
        <td className={styles.containerTableCell}>
          <Moment format="YYYY-MM-DD HH:mm" date={subscriptionsList[i].validFrom} />
        </td>
        <td className={styles.containerTableCell}>
          <Moment format="YYYY-MM-DD HH:mm" date={subscriptionsList[i].validTo} />
        </td>
        {userType !== USER_DONOR && <td className={styles.containerTableCell}>
          {subscriptionsList[i].user}
        </td>}
        <td className={styles.containerTableCell}>
          {subscriptionsList[i].subscriptionType} - {subscriptionsList[i].subscriptionPackage}
        </td>
        <td className={styles.containerTableCell}>
          {subscriptionsList[i].amount} {subscriptionsList[i].currency === 'SEK' ? 'kr' : subscriptionsList[i].currency}
        </td>
        {userType !== USER_DONOR && <td className={styles.containerTableCell}>
          <Repeat
            name={(subscriptionsList[i].recurring === "True" ? "" : "No" ) + 'RepeatSub' + (userType === USER_SUBSCRIPTION_RECIPIENT ? "Rec": "")}
            data-tippy-theme="light-border"
            className={['ml-2', 'mr-2'].join(" ")}
            strokeWidth={3}
            color={subscriptionsList[i].recurring === "True" ? 'green' : 'red'}
            size={16}/>
        </td>}
        {userType === USER_DONOR && <td className={styles.containerTableCell}>
          {subscriptionsList[i].recurring === "True" &&
          <Button
            variant="warning"
            size="sm"
            data-siteid = {subscriptionsList[i].siteId}
            data-timestamp = {subscriptionsList[i].timestamp}
            data-validto = {subscriptionsList[i].validTo}
            value={subscriptionsList[i].siteId}
            onClick={handleShow}
          >Avsluta automatisk förlängning</Button>
          }
        </td>}


        <td className={styles.containerTableCell}>
          <CreditCard color={paymentHttpColor(subscriptionsList[i].status)} size={24} />
        </td>
      </tr>
    );
  }

  return (<>
    <Card>
    <Card.Body style={{padding: "0"}}>
            <div className={[styles.containerBody].join(" ")}>
              <h6 style={{display: hasUnpaidRows ? "block" : "none"}}>
                Gul statusmarkering = köp genomfört helt eller delvis på kredit.
                Röd statusmarkering = avvaktar tillräcklig behållning.
              </h6>
              {rows.length === 0 ? (
                  <div>Inga prenumerationer att visa</div>
                ):null}
              {rows.length > 0 ? (
              <table className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

                <thead className={["thead-light", styles.containerTableHead].join(" ")}>
                <tr className={styles.containerTableHeadRow}>
                  <th className={styles.containerTableCell}>Sida</th>
                  <th className={styles.containerTableCell}>Startdatum</th>
                  <th className={styles.containerTableCell}>Slutdatum</th>
                  {userType !== USER_DONOR && <th className={styles.containerTableCell}>Prenumerant</th>}
                  <th className={styles.containerTableCell}>Prenumerationstyp</th>
                  <th className={styles.containerTableCell}>Belopp</th>
                  {userType !== USER_DONOR && <th className={styles.containerTableCell}></th>}
                  {userType === USER_DONOR && <th className={styles.containerTableCell}></th>}
                  <th className={styles.containerTableCell}>Status</th>
                </tr>
                </thead>
                <tbody className={styles.containerTableBody}>
                {rows}
                </tbody>

              </table>
              ):null}
            </div>

      <div className={classNames("mb-2", "mt-2")}>
        <Button variant="success" onClick={() => { downloadList() }} className={classNames(styles.downloadButton, "float-right", "mr-3")} >Nedladdning av prenumerationslista</Button>
      </div>
    </Card.Body>
    </Card>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Avsluta automatisk förlängning av prenumeration</Modal.Title>
      </Modal.Header>
      <Modal.Body>Är du säker på att du vill avsluta den automatiska förlängningen av
      din prenumeration?
        {_.isEmpty(selectedSub) ? "" : " Prenumerationen kommer då att upphöra den "
          + formatIsoTimestamp(selectedSub.validto) + "."}
        </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Nej, jag vill behålla automatisk förlängning
        </Button>
        <Button variant="danger" onClick={handleCancelSub}>
          Ja, förläng inte min prenumeration automatiskt
        </Button>
      </Modal.Footer>
    </Modal>

    </>
  )
}

SubscriptionsList.propTypes = {
  subscriptionsList: PropTypes.array,
}

SubscriptionsList.defaultProps = {
  subscriptionsList: [],
}

export default SubscriptionsList