import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getAdminData, getCurrentUser, handleLoginAdmin } from "../../utils/auth"

const axios = require('axios').default;
const bg_max_parser = require('bgmax-parser');

const BgMaxManager = () => {

  const [ocrToImport, setOcrToImport] = useState([])
  const userdata = getCurrentUser()
  const admincookie = getAdminData()

  const fetchUsersByOcr = async (ocr_list) => {
    let users = [];
    for (let i in ocr_list) {
      const options = {
        method: "post",
        url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/users`,
        data: {
          BrowserData: browserstring(),
          ocr: ocr_list[i].ocr
        },
      }

      try {
        const ocr_resp = await axios(options);

        users.push({ocr: ocr_list[i].ocr,
                    amount: ocr_list[i].amount,
                    bgcreference: ocr_list[i].bgcreference,
                    user: ocr_resp.data[0]})
      } catch (error) {
        if (error.response.status === 401) {
          handleLoginAdmin();
        } else if (error.response.status === 404) {
          users.push({ocr: ocr_list[i].ocr,
                amount: ocr_list[i].amount,
                    user: null})
        } else {
          throw error;
        }
      }
    }

    return users;

  }

 const onFileSelect = function (e) {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader(file);
      reader.readAsText(file,  'latin1');
      reader.onload = () => {
        let bgmax_str = reader.result;
        if (bgmax_str.endsWith("\n")) {
            bgmax_str = bgmax_str.slice(0, -2);
        }

        let bgmax_list = [];
        console.log(`----->${bgmax_str}<----`);
        const parsedBgMax = bg_max_parser(bgmax_str);

        console.log(parsedBgMax)
        for (let index in parsedBgMax.sections) {
          const section = parsedBgMax.sections[index];
          console.log(section);
          for (let paymentIdx in section.paymentsAndDeductions) {
            const paymentsAndDeductions = section.paymentsAndDeductions[paymentIdx];
            if (paymentsAndDeductions.code === "20") {
              console.log(paymentsAndDeductions.properties.reference);
              bgmax_list.push({
                  ocr: paymentsAndDeductions.properties.reference.toString(),
                  amount: paymentsAndDeductions.properties.paymentAmount/100,
                  bgcreference: paymentsAndDeductions.properties.BGCSerialNumber.toString()
                }
              );
            }
          }
        }

        fetchUsersByOcr(bgmax_list).then(result => {
          setOcrToImport(result);
        });

      }
    }
  }

  const performOcrUpdate = function() {

    let error = false;

        (async () => {

          for (let i in ocrToImport) {
            const user_name = ocrToImport[i].user.UserName;
            console.log(`Importing ${user_name}`);
            const options = {
              method: "post",
              url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/donor/${user_name}/deposit`,
              data: {
                amount: parseInt(ocrToImport[i].amount),
                BrowserData: browserstring(),
                ocr: ocrToImport[i].ocr,
                bgcreference: ocrToImport[i].bgcreference
              },
            }

            const response = await axios(options);

            if (response.status !== 200) {
              error = true;
              console.log(response);
            }
            console.log(`Finished importing ${user_name} : ${response.status}`);
          }

          if (error) {
            alert("Fel uppstod vid inläsning av BgMax. ");
          } else {
            setOcrToImport([]);
            window.location.reload();
          }
        })();

  }

  useEffect(() => {
    console.log("IN USE EFFECT: ");
    console.log(ocrToImport);
  }, [ocrToImport])

  useEffect(() => {

  }, [admincookie])

  const upload_view = (
    <div>
      Ladda upp BgMax
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroupFileAddon01">Upload</span>
        </div>
        <div className="custom-file">
          <input type="file" className="custom-file-input" id="inputGroupFile01"
                 aria-describedby="inputGroupFileAddon01" onChange={onFileSelect} />
          <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>

        </div>
      </div>
    </div>
  )

  let rows = [];

  if (ocrToImport == null) {
    rows = (<div>Läser in data från BgMax. Detta kan ta en stund ...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  } else {
    rows = [];
    for (let i = 0; i < ocrToImport.length; i++) {
      let rowClassNames = [styles.containerTableRow];
      if (ocrToImport[i].user == null ) {
        rowClassNames.push("bg-danger");
      }

      rows.push(<tr className={rowClassNames.join(" ")}>
          <td className={[styles.containerTableCell].join(" ")}>
            {ocrToImport[i].user == null ? "Okänd" : ocrToImport[i].user.UserName}
          </td>
          <td className={styles.containerTableCell}>
            {ocrToImport[i].amount}
          </td>
          <td className={styles.containerTableCell}>
            {ocrToImport[i].ocr}
          </td>
        </tr>);


    }
  }

  const confirm_view = (
    <div>
      <table className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

        <thead className={[ styles.containerTableHead].join(" ")}>
        <tr className={styles.containerTableHeadRow}>
          <th className={styles.containerTableCell}>Mottagare</th>
          <th className={styles.containerTableCell}>Belopp</th>
          <th className={styles.containerTableCell}>OCR</th>
        </tr>
        </thead>
        <tbody className={styles.containerTableBody}>
        {rows}
        </tbody>
      </table>
      <div className={["container", "mt-2"].join(" ")}>
        <div className={"row"}>
          <div className={["col-sm"].join(" ")}>
            <div className={["float-right"].join(" ")}>
        <button className={["btn", "btn-secondary", "mr-2"].join(" ")} onClick={() => {
          setOcrToImport([])
        }}>
          Ångra
        </button>

              <button className={["btn", "btn-info"].join(" ")} onClick={() => {
                performOcrUpdate();
              }}>
                Genomför
              </button>
            </div>
      </div>
      </div>
      </div>

    </div>
  )

  const main_view = (
    <div className={["p-2", "border-warning", "bg-warning"].join(" ")}>
      {ocrToImport.length > 0 ? confirm_view : upload_view }
    </div>
  )

  return main_view;
}

export default BgMaxManager