import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { api_base_url, browserstring, getCurrentUser } from "../../utils/auth"
import DonationList from "../DonationList"
import * as styles from "./main.module.css"
import UserInfo from "../user-info"
import DonatorBalance from "../DonatorBalance"
import DonationButtonCreator from "../DonationButtonCreator/DonationButtonCreator"
import { Tab, Tabs, useAccordionToggle } from "react-bootstrap"
import withUserData from "../withUserData"
import { useNavigationDispatch, useNavigationState } from "../navigation-context"
import {
  USER_DONATION_RECIPIENT,
  USER_DONOR,
  USER_PURCHASE_RECIPIENT,
  USER_SUBSCRIPTION_RECIPIENT
} from "../../utils/constants"
import SubscriptionsList from "../SubscriptionList"
import {Accordion, Card, Button } from "react-bootstrap"
import AccordionContext from "react-bootstrap/AccordionContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PurchaseList from "../PurchaseList";
import purchaseList from "../PurchaseList";

const axios = require("axios").default
const _ = require('lodash');
const classNames = require('classnames');

const CustomToggle = ({ title, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const onClickToggle = useAccordionToggle(eventKey, () => {
    callback(eventKey);
  });

  const isOpen = currentEventKey === eventKey;
  return (
    <div className={classNames(styles.donationListCardHeader, "card-header", { open: isOpen })} onClick={onClickToggle}>
      <h3 className={styles.donationListCardHeaderText}>{title}</h3>
      <i className={classNames("fa", { "fa-angle-down": !isOpen }, { "fa-angle-up": isOpen })} />
    </div>
  );
};

CustomToggle.propTypes = {
  title: PropTypes.string.isRequired,
  eventKey: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

CustomToggle.defaultProps = {
  callback: () => null,
};

const ListPanel = ({header, children}) => {
  return(
    <Accordion>
      <Card>
          <Card.Header className={styles.donationListCardHeader}>
            <CustomToggle title={header} eventKey="0"  />
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className={styles.donationListCardBody}>
              {children}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
    </Accordion>
  );
}

const DonorLists = ({username, cookie, accountdata, creditdata,
                      donationList,
                      subscriptionsList, purchaseList,
                      subscriptionsListSetter, refreshFunc}) => {

  const [defaultActiveKey, setDefaultActiveKey] = useState("0")

  useEffect(() => {
    setDefaultActiveKey((donationList===null || donationList.length === 0) ? "1" : "0");
  }, [donationList, subscriptionsList]);

  return (
    <div className={["col-12"].join(" ")}>
      <DonatorBalance user_name={username} user_type={USER_DONOR} account_data={accountdata} credit_data={creditdata} cookie={cookie}/>

      {(donationList != null && donationList.length > 0) && <ListPanel header={"Mina senaste donationer"}>
        <DonationList
          donationList={donationList}
          isRecipient={false}
          refreshFunc={refreshFunc}/>
      </ListPanel>}

      {(subscriptionsList != null && subscriptionsList.length > 0) && <ListPanel header={"Mina prenumerationer"}>
        <SubscriptionsList subscriptionsList={subscriptionsList} userType={USER_DONOR} dataUpdateCallback={subscriptionsListSetter}/>
      </ListPanel>}

      <ListPanel header={"Mina köp"}>
        <PurchaseList purchaseList={purchaseList} userType={USER_DONOR}/>
      </ListPanel>

    </div>
  )
};

DonorLists.propTypes = {
    username: PropTypes.string.isRequired,
    cookie: PropTypes.string.isRequired,
    accountdata: PropTypes.object,
    creditdata: PropTypes.object,
    donationList: PropTypes.array,
    subscriptionsList: PropTypes.array,
    refreshFunc: PropTypes.func,
  };

  DonorLists.defaultProps = {
    username: "",
    cookie: "",
    donationList: [],
    subscriptionsList: [],
  };


const Profile = (props) => {
  const { username, cookie } = getCurrentUser()
  const [donationList, setDonationList] = useState(null)
  const [receivedList, setReceivedList] = useState(null)
  const [donorSubscriptionsList, setDonorSubscriptionsList] = useState(null)
  const [recipientSubscriptionsList, setRecipientSubscriptionsList] = useState(null)
  const [donorPaymentsList, setDonorPaymentsList] = useState(null)
  const [recipientPaymentsList, setRecipientPaymentsList] = useState(null)

  useNavigationState()
  useNavigationDispatch()

  const donationpage_path = props.location.pathname+props.location.search;

  const fetchDonations = (dataCallback, isRecipient) => {
    const options = {
      method: "post",
      url: `${api_base_url()}/${cookie}/donationhistory`,
      data: {
        BrowserData: browserstring(),
        Limit: 10,
        isRecipient: isRecipient,
      },
    }

    axios(options)
      .then(response => {
        if (response.status === 204) {
          dataCallback([])
        } else {
          dataCallback(response.data)
        }
      })
  }

  const fetchPayments = (dataCallback, usertype) => {
    const options = {
      method: "post",
      url: `${api_base_url()}/${cookie}/payments`,
      data: {
        BrowserData: browserstring(),
        Limit: 10,
        userType: usertype,
      },
    }

    axios(options)
        .then(response => {
          if (response.status === 204) {
            dataCallback([])
          } else {
            dataCallback(response.data)
          }
        })
  }


  const fetchSubscriptions = (dataCallback, usertype) => {
    const options = {
      method: "post",
      url: `${api_base_url()}/${cookie}/subscriptions`,
      data: {
        BrowserData: browserstring(),
        Limit: 10,
        userType: usertype,
      },
    }

    axios(options)
      .then(response => {
        if (response.status === 204) {
          dataCallback([])
        } else {
          dataCallback(response.data)
        }
      })
  }

  function refreshLists() {
    if (props.userData) {
      if (props.userData.Account_A) {
        fetchDonations(setDonationList, false)

        fetchSubscriptions(setDonorSubscriptionsList, USER_DONOR);
        fetchPayments(setDonorPaymentsList, USER_DONOR);
      }
      if (props.userData.Account_B) {
        fetchDonations(setReceivedList, true);
      }
      if (props.userData.Account_C) {
        fetchSubscriptions(setRecipientSubscriptionsList, USER_SUBSCRIPTION_RECIPIENT);
      }
      if (props.userData.Account_D) {
        fetchPayments(setRecipientPaymentsList, USER_PURCHASE_RECIPIENT);
      }
    }
  }

  useEffect(() => {
    refreshLists();
  }, [props.userData, cookie])


  let nrOfAccounts = _.reduce(props.userData, function(result, value, key) {
    if (key.startsWith('Account_'))
      return result + 1;
    else
      return result;
  }, 0);

  let defaultTab = "donator";
  if (!props.userData.Account_A){
    if (props.userData.Account_B) {
      defaultTab = "mottagare"
    } else if (props.userData.Account_C) {
      defaultTab = "prenumeranter";
    }
  }


  if (nrOfAccounts > 1) {
    return (
      <div className={["container-fluid"].join(" ")}>
        <div className={["row", "justify-content-center", styles.container].join(" ")}>
          <div className={["col-12", "mt-4"].join(" ")}>
            <UserInfo user_name={username} user_email={props.userEmail}/>

            <Tabs defaultActiveKey={defaultTab} id="profile-type-tabs">
              {props.userData.Account_A ? (
                <Tab eventKey="donator" title="Donator">
                  <div className={["my-3"].join(" ")}>

                    <DonorLists username={username}
                                accountdata={props.userData.Account_A}
                                creditdata={props.userData.Credit}
                                cookie={cookie}
                                donationList={donationList}
                                subscriptionsList={donorSubscriptionsList}
                                subscriptionsListSetter={setDonorSubscriptionsList}
                                purchaseList={donorPaymentsList}
                                refreshFunc={refreshLists}/>
                  </div>
                </Tab>) : null}
              {props.userData.Account_B ? (
                <Tab eventKey="mottagare" title="Donationer">
                  <div className="mt-3">

                    <Container>
                      <Row>
                        <Col xs={12} md={10}>
                          <DonatorBalance user_name={username} user_type={USER_DONATION_RECIPIENT} account_data={props.userData.Account_B} credit_data={props.userData.DonationCredit} cookie={cookie}/>
                        </Col>
                        <Col xs={12} md={2}>
                          <DonationButtonCreator recipient_account_id={props.userData.Account_B.AccountId} cookie={cookie}/>
                        </Col>
                      </Row>
                    </Container>

                    <DonationList
                      donationList={receivedList}
                      isRecipient={true}
                      refreshFunc={refreshLists}
                    />
                  </div>
                </Tab>) : null}

              {props.userData.Account_C ? (
                <Tab eventKey="prenumeranter" title="Prenumeranter">
                  <div className="mt-3">
                    <Container>
                      <Row>
                        <Col xs={12} md={10}>
                          <DonatorBalance user_name={username} user_type={USER_SUBSCRIPTION_RECIPIENT} isDonor={false} account_data={props.userData.Account_C} credit_data={props.userData.SubscriptionCredit} cookie={cookie}/>
                        </Col>
                      </Row>
                    </Container>

                    <SubscriptionsList subscriptionsList={recipientSubscriptionsList} userType={USER_SUBSCRIPTION_RECIPIENT}/>
                  </div>
                </Tab>) : null}

              {props.userData.Account_D ? (
                  <Tab eventKey="kop" title="Köp">
                    <div className="mt-3">
                      <Container>
                        <Row>
                          <Col xs={12} md={10}>
                            <DonatorBalance user_name={username} user_type={USER_PURCHASE_RECIPIENT} isDonor={false} account_data={props.userData.Account_D} credit_data={props.userData.PurchaseCredit} cookie={cookie}/>
                          </Col>
                        </Row>
                      </Container>

                      <PurchaseList purchaseList={recipientPaymentsList} userType={USER_PURCHASE_RECIPIENT}/>
                    </div>
                  </Tab>) : null}
            </Tabs>
          </div>
        </div>
      </div>
    )
  } else if (props.userData.Account_A) {
    return (
      <div className={["container-fluid"].join(" ")}>
        <div className={["row", "justify-content-center", styles.container].join(" ")}>
          <div className={["mt-4"].join(" ")}>
            <UserInfo user_name={username} user_email={props.userEmail}/>
          </div>

          <DonorLists username={username}
                        accountdata={props.userData.Account_A}
                        creditdata={props.userData.Credit}
                        cookie={cookie}
                        donationList={donationList}
                        subscriptionsList={donorSubscriptionsList}
                        subscriptionsListSetter={setDonorSubscriptionsList}
                        purchaseList={donorPaymentsList}
                        refreshFunc={refreshLists} />

        </div>
      </div>
    )
  } else if (props.userData.Account_B) {
    return (
      <div className={["container-fluid"].join(" ")}>
        <div className={["row", "justify-content-center", styles.container].join(" ")}>
          <div className={["mt-4"].join(" ")}>
            <UserInfo user_name={username} user_email={props.userEmail}/>
          </div>

          <div className={["col-12"].join(" ")}>
            <Container>
              <Row>
                <Col xs={12} md={10}>
                  <DonatorBalance user_name={username} user_type={USER_DONATION_RECIPIENT} account_data={props.userData.Account_B} credit_data={props.userData.DonationCredit}  cookie={cookie}/>
                </Col>
                <Col xs={12} md={2}>
                  <DonationButtonCreator recipient_account_id={props.userData.Account_B.AccountId} cookie={cookie}/>
                </Col>
              </Row>
            </Container>

            <DonationList
              donationList={receivedList}
              isRecipient={true}
              refreshFunc={refreshLists}/>
          </div>

        </div>
      </div>
    )
  } else if (props.userData.Account_C) {
    return (
        <div className={["container-fluid"].join(" ")}>
          <div className={["row", "justify-content-center", styles.container].join(" ")}>
            <div className={["mt-4"].join(" ")}>
              <UserInfo user_name={username} user_email={props.userEmail}/>
            </div>

            <div className={["col-12"].join(" ")}>
              <Container>
                <Row>
                  <Col xs={12} md={10}>
                    <DonatorBalance user_name={username} user_type={USER_SUBSCRIPTION_RECIPIENT} account_data={props.userData.Account_C} credit_data={props.userData.SubscriptionCredit} cookie={cookie}/>
                  </Col>
                </Row>
              </Container>

              <SubscriptionsList subscriptionsList={recipientSubscriptionsList} userType={USER_SUBSCRIPTION_RECIPIENT}/>
            </div>

          </div>
        </div>
    )
  } else if (props.userData.Account_D) {
    return (
        <div className={["container-fluid"].join(" ")}>
          <div className={["row", "justify-content-center", styles.container].join(" ")}>
            <div className={["mt-4"].join(" ")}>
              <UserInfo user_name={username} user_email={props.userEmail}/>
            </div>

            <div className={["col-12"].join(" ")}>
              <Container>
                <Row>
                  <Col xs={12} md={10}>
                    <DonatorBalance user_name={username} user_type={USER_PURCHASE_RECIPIENT} account_data={props.userData.Account_D} credit_data={props.userData.PurchaseCredit} cookie={cookie}/>
                  </Col>
                </Row>
              </Container>

              <PurchaseList purchaseList={recipientPaymentsList} userType={USER_PURCHASE_RECIPIENT}/>
            </div>

          </div>
        </div>
    )
  }
}

export default withUserData(Profile)
