// extracted by mini-css-extract-plugin
export var bold = "main-module--bold--0-VG5";
export var borderMedium = "main-module--border-medium--e2nRz";
export var closeButtonContainer = "main-module--closeButtonContainer--IZi0L";
export var container = "main-module--container--qYhEx";
export var description = "main-module--description--m1eZf";
export var donation = "main-module--donation--az7rq";
export var errormessage = "main-module--errormessage--lUcdj";
export var nameheader = "main-module--nameheader--2-KIN";
export var outercontainer = "main-module--outercontainer--MeTFL";
export var refillcard = "main-module--refillcard--DqSsc";
export var refillcontainer = "main-module--refillcontainer--a4dAu";