import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Profile from "../components/Profile/index"
import Details from "../components/Details"
import Refill from "../components/Refill"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import Register from "../components/Register"
import AdminRoute from "../components/AdminRoute"
import Admin from "../components/Admin"
import Confirmation from "../components/Confirmation"
import Donera from "./donera"
import withLocation from "../components/withLocation"
import withJWT from "../components/withJWT"
import AdminListUsers from "../components/AdminListUsers"
import AdminListSubscriptions from "../components/AdminListSubscriptions"
import { VISITOR_DONATION } from "../utils/constants"

const App = (props) => {

  return (
    <Layout>
      <Status {...props} menuoptions={{ logout: true,
                                        close: false,
                                        returnToDonation: true,
                                        visitorType: VISITOR_DONATION
                                      }} />
      <Router>
        <Donera path="/donera" />
        <AdminRoute path="/app/admin" component={Admin}/>
        <AdminRoute path="/app/admin/users" component={AdminListUsers}/>
        <AdminRoute path="/app/admin/subscriptions" component={AdminListSubscriptions}/>
        <PrivateRoute path="/app/details" component={Details}/>
        <PrivateRoute path="/app/profile" component={withLocation(Profile)}/>
        <PrivateRoute path="/app/refill" component={withLocation(Refill)}/>
        <Register path="/app/register" component={Register}/>
        <Confirmation path="/confirm" component={Confirmation}/>
        <Login path="/app/login"/>
      </Router>
    </Layout>
  )
}

export default withJWT(App)
