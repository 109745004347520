import React from "react"
import * as styles from "./main.module.css"

const BgPayment = ({account, enabled=true}) => {

  return (
    <div className={["container", "mb-1"].join(" ")}>
      <div style={{ display: enabled ? "block" : "none" }}>
        Skicka betalning till:
        <p>
        Bankgiro: <span className={styles.bold}>5515-0015</span> (MediaLike)
          </p>
        <p>
          OCR: <span className={styles.bold}>{account.OCR}</span>
          </p>
        <p>
          Det är viktigt att använda din OCR-kod vid betalning.
          Valfritt belopp i hela kronor större än 10 kr kan betalas.
          Vi rekommenderar minst 100 kr för att minska behovet av att fylla på ofta.
        </p>
        <p>
          Det kan dröja upp till flera dygn innan ditt donationskonto visar inbetalningen,
          men du kan göra upp till 20 donationer redan nu fast de överförs inte till
          mottagarna förrän ditt donationskonto har tillräcklig behållning.
        </p>
      </div>

      <div style={{ display: enabled ? "none" : "block" }}>
        <span className={styles.bold}>
          Bankgironummer och OCR-nummer kommer att visas här efter
          att du har bekräftat din epostadress.
        </span>
      </div>
    </div>
  )
}

export default BgPayment
