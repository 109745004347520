import React, { useState } from "react"
import { api_base_url, browserstring, getCurrentUser } from "../utils/auth"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"

const axios = require("axios").default

const PasswordChange = () => {
  const userdata = getCurrentUser()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div style={{display: isOpen ? "block" : "none", margin: "auto", position: "absolute", left: "50%", top: "0"}}>
      <div className={["card", "ml-3"].join(" ")} style={{ zIndex: "301" }}>
        <div className={["container", "mb-1"].join(" ")}>
          <Formik initialValues={{
            old_password: "",
            new_password: "",
            password_repeat: "",
          }}
                  validationSchema={Yup.object().shape({
                    old_password: Yup.string()
                      .required("Du måste ange ditt gamla lösenord"),
                    new_password: Yup.string()
                      .min(6, "Det nya lösenordet måste innehålla minst 6 tecken")
                      .required("Du måste ange ett lösnord"),
                    password_repeat: Yup.string()
                      .oneOf([Yup.ref("new_password"), null], "Lösenordet matchar inte")
                      .required("Du måste bekräfta ditt nya lösenord"),
                  })}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false)

                    const options = {
                      method: "post",
                      url: `${api_base_url()}/${userdata.cookie}/profile/password`,
                      data: {
                        new_password: values.new_password,
                        browser_data: browserstring(),
                      },
                    }

                    axios(options)
                      .then(response => {
                        actions.setSubmitting(false)
                        if (response.status !== 200) {
                          actions.setFieldError("old_password", "Kunde inte byta lösenord")
                        } else {
                          setIsOpen(false)
                        }
                      })
                  }}
          >
            {({ errors, touched, isValidating, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="old_password">Ange ditt gamla lösenord</label>
                  <Field type="password" name="old_password"
                         className={"form-control" + (errors.old_password && touched.old_password ? " is-invalid" : "")}/>
                  <ErrorMessage name="old_password" component="div" className="invalid-feedback"/>
                </div>
                <div className="form-group">
                  <label htmlFor="new_password">Nytt lösenord</label>
                  <Field type="password" name="new_password"
                         className={"form-control" + (errors.new_password && touched.new_password ? " is-invalid" : "")}/>
                  <ErrorMessage name="new_password" component="div" className="invalid-feedback"/>
                </div>
                <div className="form-group">
                  <label htmlFor="password_repeat">Lösenord igen</label>
                  <Field type="password" name="password_repeat"
                         className={"form-control" + (errors.password_repeat && touched.password_repeat ? " is-invalid" : "")}/>
                  <ErrorMessage name="password_repeat" component="div" className="invalid-feedback"/>
                </div>
                <button className={["btn", "btn-warning", "mr-2"].join(" ")} type="submit" disabled={isSubmitting}>
                  Spara nytt lösenord
                </button>
                <button className={["btn", "btn-info"].join(" ")} onClick={() => {
                  setIsOpen(false)
                }}>
                  Ångra
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        <div style={{position: "fixed", zIndex: "300", backgroundColor: "rgba(23, 23, 23, 0.7)", top: "0", left: "0", height: "100vh", width: "100vw"}}>

        </div>
      </div>
      <div className={["container", "mb-1"].join(" ")} style={{ display: !isOpen ? "block" : "none" }}>
        <button className={["btn", "btn-warning", "btn-sm", "py-0"].join(" ")} style={{ fontSize: "0.8em"}}
                onClick={(e) => {
          setIsOpen(true)
        }}>Byt lösenord
        </button>
      </div>
    </div>
  )
}

export default PasswordChange
