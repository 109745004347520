// extracted by mini-css-extract-plugin
export var closeButtonContainer = "main-module--closeButtonContainer--4fFQU";
export var container = "main-module--container--eOrPz";
export var description = "main-module--description--yZ7ux";
export var donation = "main-module--donation--8620d";
export var donationListCardBody = "main-module--donation-list-card-body--Lzt6y";
export var donationListCardHeader = "main-module--donation-list-card-header--JPzuk";
export var donationListCardHeaderText = "main-module--donation-list-card-header-text--vrZif";
export var donationListCardToggle = "main-module--donation-list-card-toggle--q7Sie";
export var errormessage = "main-module--errormessage--JRgg8";
export var nameheader = "main-module--nameheader--MpcRJ";
export var outercontainer = "main-module--outercontainer--qwLMq";