import React from "react"
import { api_base_url, browserstring, getAdminData, getCurrentUser } from "../utils/auth"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"

const axios = require("axios").default

const PerformPayout = ({account_id_b, account_id_c, account_id_d, closeCallback}) => {
  const userdata = getCurrentUser()
  const admincookie = getAdminData()

  return (
    <div>
      <div className={["card", "ml-3"].join(" ")} >
        <div className={["container", "mb-1"].join(" ")}>

          <Formik initialValues={{
            payout_amount: 0,
            selected_account: "",
          }}
                  validationSchema={Yup.object().shape({
                    payout_amount: Yup.number()
                      .required("Du måste ange hur stor utbetalningen ska vara"),
                  })}
                  onSubmit={(values, actions) => {

                    let payout_account_id = "";
                    if (values.selected_account === "donationskonto") {
                      payout_account_id = account_id_b;
                    } else if (values.selected_account === "prenumerationskonto") {
                      payout_account_id = account_id_c;
                    } else if (values.selected_account === "kopkonto") {
                      payout_account_id = account_id_d;
                    }

                    actions.setSubmitting(false)

                    const options = {
                      method: "post",
                      url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/recipient/${payout_account_id}/payout`,
                      data: {
                        amount: parseInt(values.payout_amount),
                        BrowserData: browserstring(),
                      },
                    }

                    axios(options)
                      .then(response => {
                        actions.setSubmitting(false)
                        if (response.status !== 200) {
                          actions.setFieldError("payout_amount", "Kunde inte genomföra utbetalningen")
                        } else {
                          closeCallback()
                        }
                      })
                  }}
          >
            {({ values, errors, touched, isValidating, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <div role="group" aria-labelledby="account-radio-group"  style={{marginBottom: "20px"}}>
                    <label style={{marginRight: "15px"}}>
                      <Field type="radio" disabled={!account_id_b} name="selected_account" value="donationskonto" style={{marginRight: "3px"}}/>
                      Donationskonto
                    </label>
                    <label>
                      <Field type="radio" disabled={!account_id_c} name="selected_account" value="prenumerationskonto" style={{marginRight: "3px"}}/>
                      Prenumerationskonto
                    </label>
                    <label>
                      <Field type="radio" disabled={!account_id_d} name="selected_account" value="kopkonto" style={{marginRight: "3px"}}/>
                      Köpkonto
                    </label>
                    <div style={{fontWeight:"bolder"}} hidden={!values.selected_account}>Utbetalning sker från {values.selected_account}</div>
                  </div>


                  <label htmlFor="payout_amount">Ange vilket belopp som ska betalas ut</label>
                  <Field type="text" name="payout_amount"
                         className={"form-control" + (errors.payout_amount && touched.payout_amount ? " is-invalid" : "")}/>
                  <ErrorMessage name="payout_amount" component="div" className="invalid-feedback"/>
                </div>
                <button className={["btn", "btn-warning", "mr-2"].join(" ")} type="submit" disabled={isSubmitting || !values.selected_account || values.payout_amount === 0}>
                  Genomför utbetalning
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default PerformPayout
