import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import {
  isLoggedIn,
  handleLoginAdmin,
  isAdmin,
  getAdminData,
} from "../utils/auth"
import withLocation from "./withLocation"

const AdminRoute = ({ component: Component, location, ...rest }) => {
  const [adminData, setAdminData] = useState(null)

  useEffect(() => {
    handleLoginAdmin((elevated) => {
      const admin_cookie = getAdminData();
      if (isAdmin()) {
        setAdminData(admin_cookie);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (!isLoggedIn()) {

    // If we’re not logged in, redirect to the home page.
    const redirectParam = encodeURIComponent(location.pathname+location.search)
    if(typeof window !== `undefined`) {
      navigate(`/app/login?source=`+redirectParam);
    }

    return null
  }

  if (adminData) {
    return (<Component {...rest} />);
  } else {
    return (<div>Kontrollerar dina åtkomsträttigheter...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  }
}

AdminRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default withLocation(AdminRoute)
