import React from "react"
import { api_base_url, browserstring, getAdminData, getCurrentUser } from "../utils/auth"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"
const axios = require("axios").default

const AdminDeactivateRecipient = ({user_to_deactivate, closeCallback}) => {
  const userdata = getCurrentUser()
  const admincookie = getAdminData()

  return (
    <div>
      <div className={["card", "ml-3"].join(" ")} >
        <div className={["container", "mb-1"].join(" ")}>

          <Formik
                  initialValues={{
                    acceptTerms: false
                  }}
                  validationSchema={Yup.object().shape({
                    acceptTerms: Yup.bool().oneOf([true], 'Du måste bekräfta borttagningen')
                  })}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false)

                    const options = {
                      method: "post",
                      url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/deactivate`,
                      data: {
                        user: user_to_deactivate,
                        BrowserData: browserstring(),
                      },
                    }

                    axios(options)
                      .then(response => {
                        actions.setSubmitting(false)
                        if (response.status !== 200) {
                          actions.setFieldError("payout_amount", "Kunde inte inaktivera användaren")
                        } else {
                          closeCallback()
                        }
                      })
                  }}
          >
            {({ errors, touched, isValidating, isSubmitting }) => (
              <Form>
                <div className="form-group form-check">
                  <Field type="checkbox" name="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                  <label htmlFor="acceptTerms" className="form-check-label">Jag vill ta bort kontot</label>
                  <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                </div>
                <button className={["btn", "btn-danger", "mr-2"].join(" ")} type="submit" disabled={isSubmitting}>
                  Ta bort {user_to_deactivate}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AdminDeactivateRecipient
