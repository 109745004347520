import React, { useState } from "react"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { api_base_url, browserstring, medialike_images_base_url } from "../../utils/auth"
import styled from "styled-components"

const axios = require("axios").default

const numberInString = (inputString) => {
  const r = /\d+/g;
  let m;
  if ((m = r.exec(inputString)) != null) {
    return(m[0]);
  }
  return 1;
};

const DonationButtonCreator = ({ recipient_account_id, cookie }) => {
  const [displayCode, setDisplayCode] = useState(0)
  const [selectedLinkType, setSelectedLinkType] = useState("Bild")
  const [selectedColor, setSelectedColor] = useState("#a9d7a0")
  const [selectedTextColor, setSelectedTextColor] = useState("#000000")
  const [selectedText, setSelectedText] = useState("Gilla på riktigt!")
  const [selectedExtraText, setSelectedExtraText] = useState("Donera 1 kr eller mer")
  const [selectedExtraText2, setSelectedExtraText2] = useState("")
  const [selectedBelopp, setSelectedBelopp] = useState("1 kr")
  const [selectedSize, setSelectedSize] = useState("medium")
  const [selectedAmounts, setSelectedAmounts] = useState([1, 5, 10])
  const [buttonImageUrl, setButtonImageUrl] = useState("")

  const showCode = () => setDisplayCode(2)
  const showForm = () => setDisplayCode(1)
  const showButton = () => setDisplayCode(0)
  const hideExtraConfig = true
  const is_prod = () => {
    return process.env.MEDIALIKE_ENV === "PRD"
  }

  const medialike_base_url = () => {
    return process.env.MEDIALIKE_URL
  }

  const ModalOverlay = styled.div`
    position: fixed;
    z-index: 300;
    background-color: rgba(23, 23, 23, 0.7);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  `

  const ModalFocus = styled.div`
    margin: auto;
    position: absolute;
    min-width: 400px;
    left: -50%;
    top: 0;
  `

  const script_code = `<script type="application/javascript" src="${medialike_base_url()}/js/medialinq.min.js"></script>`

  const iframe_code = `<div class="donera-container-${selectedSize} donera-bordered">
                          <iframe
                                  class="donera_button"
                                  ${is_prod() ? "" : "data-benv=\"1\""}
                                  data-header="${selectedText}" 
                                  data-extra="${selectedExtraText}" 
                                  data-extra2="${selectedExtraText2}"
                                  data-recipient="${recipient_account_id}" 
                                  ${selectedExtraText2 ? "data-amounts=\""+selectedAmounts[0]+"\"" : "data-amounts=\""+selectedAmounts[0] + "," + selectedAmounts[1]+ "," + selectedAmounts[2] + "\""}
                                  scrolling="no">
                          </iframe>
                          </div>
                          `

  const text_link_code = `${medialike_base_url()}/checkout/index.html?ol=d&rcp=${recipient_account_id}&sel=Donation&origin=referrer&a=5`

  const prepareCode = async (link_type, header_text, main_text, extra_text2, belopp, color, text_color, size) => {
    let width = 500
    let height = 85

    switch (size) {
      case "tiny": {
        width = Math.ceil(width * 0.5)
        height = Math.ceil(height * 0.5)
        break
      }
      case "small": {
        width = Math.ceil(width * 0.8)
        height = Math.ceil(height * 0.8)
        break
      }
      case "medium": {
        width = Math.ceil(width * 1)
        height = Math.ceil(height * 1)
        break
      }
      case "large": {
        width = Math.ceil(width * 1.5)
        height = Math.ceil(height * 1.5)
        break
      }
      default: {
        width = Math.ceil(width * 1)
        height = Math.ceil(height * 1)
        break
      }
    }

    if (link_type === "Bild") {
      const options = {
        method: "post",
        url: `${api_base_url()}/${cookie}/image`,
        data: {
          BrowserData: browserstring(),
          width: width,
          height: height,
          text: header_text,
          extra: main_text,
          extra2: extra_text2,
          belopp: belopp,
          bgcolor: color,
          color: text_color,
        },
      }

      try {
        const response = await axios(options)
        if (response.status !== 200) {
          return false
        } else {
          setButtonImageUrl(`${medialike_images_base_url()}/${response.data}`)
          return true
        }

      } catch (error) {
        return false
      }
    } else {
      return true
    }
  }

  const ButtonCreatorSchema = Yup.object().shape({
    button_belopp_1: Yup.number()
      .min(1, "Kan inte vara lägre än 1")
      .required("Du måste ange ett belopp"),
    button_belopp_2: Yup.number()
      .min(1, "Kan inte vara lägre än 1")
      .required("Du måste ange ett belopp"),
    button_belopp_3: Yup.number()
      .min(1, "Kan inte vara lägre än 1")
      .required("Du måste ange ett belopp"),
  })

  if (displayCode === 0) {
    return (<button className={["btn", "btn-primary", "mb-3", "px-5"].join(" ")} onClick={() => {
      showForm()
    }}>Hämta donationsknapp</button>)
  } else if (displayCode === 2) {

    if (selectedLinkType === "Text") {
      return (
        <ModalFocus>
          <div style={{ zIndex: "301" }}
               className={["card", "p-3", "m-3", "bg-secondary", "text-left", "text-light"].join(" ")}>
            <div className={["col-12"].join(" ")}>
              <div className={["mb-5"].join(" ")}>
                <p>Använd denna URL för att skapa en länk på den websida som ska innehålla donationsknappen.
                </p>
                <div className={["card", "p-3", "m-3", "bg-light"].join(" ")}>
                  <code className="language-markup">{text_link_code}</code>
                </div>
              </div>
              <div className={["col-12", "mt-3"].join(" ")}>
                <button className={["btn", "btn-primary", "mb-3", "px-5", "mr-1"].join(" ")} onClick={() => {
                  showForm()
                }}>Ändra
                </button>

                <button className={["btn", "btn-warning", "mb-3", "px-5", "ml-1"].join(" ")} onClick={() => {
                  showButton()
                }}>Stäng och återgå
                </button>
              </div>

            </div>
          </div>
          <ModalOverlay>

          </ModalOverlay>
        </ModalFocus>
      )
    } else if (selectedLinkType === "Bild") {
      const startAmount = numberInString(selectedBelopp)

      const image_link_code = `<a href="${medialike_base_url()}/checkout/index.html?ol=d&rcp=${recipient_account_id}&sel=Donation&origin=referrer&a=${startAmount}"><img src="${buttonImageUrl}" /></a>`
      return (
        <ModalFocus>
          <div style={{ zIndex: "301" }}
               className={["card", "p-3", "m-3", "bg-secondary", "text-left", "text-light"].join(" ")}>
            <div className={["col-12"].join(" ")}>
              <div className={["mb-5"].join(" ")}>
                <div className={["p-3", "m-3", "bg-light"].join(" ")}>
                  <img alt="" src={buttonImageUrl}/>
                </div>
                <p>För att använda denna bild lägger du in följande kod på din webbsida.
                </p>
                <div className={["card", "p-3", "m-3", "bg-light"].join(" ")}>
                  <code className="language-markup">{image_link_code}</code>
                </div>
              </div>
              <div className={["col-12", "mt-3"].join(" ")}>
                <button className={["btn", "btn-primary", "mb-3", "px-5", "mr-1"].join(" ")} onClick={() => {
                  showForm()
                }}>Ändra
                </button>

                <button className={["btn", "btn-warning", "mb-3", "px-5", "ml-1"].join(" ")} onClick={() => {
                  showButton()
                }}>Stäng och återgå
                </button>
              </div>

            </div>
          </div>
          <ModalOverlay>

          </ModalOverlay>
        </ModalFocus>
      )
    } else {
      return (
        <ModalFocus>
          <div style={{ zIndex: "301" }}
               className={["card", "p-3", "m-3", "bg-secondary", "text-left", "text-light"].join(" ")}>
            <div className={["col-12"].join(" ")}>
              <div className={["mb-5"].join(" ")}>
                <p>
                  För att placera en donationsknapp på en webbsida behöver två stycken html-kod
                  infogas till sidan. Nedan återfinns de två kodavsnitten med beskrivning av
                  hur dessa ska användas.
                </p>
                <p>Denna kod ska placeras exakt en gång på varje websida som ska innehålla donationsknappen.
                  Koden ska placeras precis innan
                  &lt;/body&gt;
                </p>
                <div className={["card", "p-3", "m-3", "bg-light"].join(" ")}>
                  <code className="language-markup">{script_code}</code>
                </div>
              </div>
              <div className={["mb-4"].join(" ")}>
                <p>Nedanstående kod ska placeras på det ställe på webbsidan där du önskar att knappen ska visas.
                  Om donationsknappen ska placeras på flera olika ställen på samma webbsida så kopieras och placeras
                  koden upprepade gånger.</p>
                <div className={["card", "p-3", "m-3", "bg-light"].join(" ")}>
                  <code className="language-markup">{iframe_code}</code>
                </div>
              </div>
              <div className={["col-12", "mt-3"].join(" ")}>
                <button className={["btn", "btn-primary", "mb-3", "px-5", "mr-1"].join(" ")} onClick={() => {
                  showForm()
                }}>Ändra
                </button>

                <button className={["btn", "btn-warning", "mb-3", "px-5", "ml-1"].join(" ")} onClick={() => {
                  showButton()
                }}>Stäng och återgå
                </button>
              </div>

            </div>
          </div>
          <ModalOverlay>

          </ModalOverlay>
        </ModalFocus>
      )
    }
  } else {
    let singleButton = false;
    return (
      <ModalFocus>
        <div style={{ zIndex: "301" }}
             className={["card", "p-3", "m-3", "bg-secondary", "text-left", "text-light"].join(" ")}>

          <Formik
            initialValues={{
            link_category: selectedLinkType,
            background_color: selectedColor,
            text_color: selectedTextColor,
            button_text: selectedText,
            button_extra_text: selectedExtraText,
            button_extra_text2: selectedExtraText2,
            button_belopp: selectedBelopp,
            button_size: selectedSize,
            button_belopp_1: selectedAmounts[0],
            button_belopp_2: selectedAmounts[1],
            button_belopp_3: selectedAmounts[2],
          }}
                  validationSchema={ButtonCreatorSchema}

                  onSubmit={(values) => {
                    setSelectedLinkType(values.link_category)
                    setSelectedColor(values.background_color)
                    setSelectedTextColor(values.text_color)
                    setSelectedText(values.button_text)
                    setSelectedExtraText(values.button_extra_text)
                    setSelectedExtraText2(values.button_extra_text2)
                    setSelectedBelopp(values.button_belopp)
                    setSelectedSize(values.button_size)
                    setSelectedAmounts([values.button_belopp_1, values.button_belopp_2, values.button_belopp_3])

                    prepareCode(values.link_category, values.button_text, values.button_extra_text, values.button_extra_text2, values.button_belopp, values.background_color, values.text_color, values.button_size).then(value => showCode())
                  }}
          >
            {({ errors, touched, isValidating, isSubmitting, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="link_category">Typ av länk</label>
                  <Field name="link_category" component="select"
                         className={"form-control" + (errors.link_category && touched.link_category ? " is-invalid" : "")}>
                    <option value="Text">Text</option>
                    <option value="Bild">Bild</option>
                    <option value="Javascript">Javascript</option>
                  </Field>
                  <ErrorMessage name="link_category" component="div" className="invalid-feedback"/>
                </div>


                {values.link_category !== "Text" &&
                <fieldset>

                  <div className="form-group">
                    <div className="form-group">
                      <label htmlFor="button_text">Rubrik</label>
                      <Field type="text" name="button_text" maxLength={44}
                             className={"form-control" + (errors.button_text && touched.button_text ? " is-invalid" : "")}/>
                      <ErrorMessage name="button_text" component="div" className="invalid-feedback"/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="button_extra_text">Textrad</label>
                      <Field type="text" name="button_extra_text" maxLength={35}
                             className={"form-control" + (errors.button_extra_text && touched.button_extra_text ? " is-invalid" : "")}/>
                      <ErrorMessage name="button_extra_text" component="div" className="invalid-feedback"/>
                    </div>

                    <div className="form-group" style={{ display: values.link_category === "Text" ? "none" : "" }}>
                      <label htmlFor="button_extra_text2">Textrad 2</label>
                      <Field
                        onKeyUp={e => {
                          singleButton = e.target.value ? true : false;
                        }}
                        type="text" name="button_extra_text2" maxLength={35}
                             className={"form-control" + (errors.button_extra_text2 && touched.button_extra_text2 ? " is-invalid" : "")}/>
                      <ErrorMessage name="button_extra_text2" component="div" className="invalid-feedback"/>
                    </div>


                    <div className="form-group" style={{ display: values.link_category !== "Bild" ? "none" : "" }}>
                      <label htmlFor="button_belopp">Belopp</label>
                      <Field type="text" name="button_belopp" maxLength={5}
                             className={"form-control" + (errors.button_belopp && touched.button_belopp ? " is-invalid" : "")}/>
                      <ErrorMessage name="button_belopp" component="div" className="invalid-feedback"/>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="button_size">Storlek på knappen</label>
                    <Field as="select" name="button_size" className="form-control">
                      {values.link_category === "Javascript" &&
                      <option value="micro">Mikro</option>
                      }
                      <option value="tiny">Mycket liten</option>
                      <option value="small">Liten</option>
                      <option value="medium">Medium</option>
                      <option value="large">Stor</option>
                    </Field>
                  </div>

                  <div className="form-group" style={{ display: hideExtraConfig ? "none" : "" }}>
                    <label htmlFor="background_color">Bakgrundsfärg</label>
                    <Field type="color" name="background_color"
                           className={"form-control" + (errors.background_color && touched.background_color ? " is-invalid" : "")}/>
                    <ErrorMessage name="background_color" component="div" className="invalid-feedback"/>
                  </div>
                </fieldset>
                }

                {values.link_category === "Bild" &&
                <fieldset>
                  <div className="form-group" style={{ display: hideExtraConfig ? "none" : "" }}>
                    <label htmlFor="text_color">Textfärg</label>
                    <Field type="color" name="text_color"
                           className={"form-control" + (errors.text_color && touched.text_color ? " is-invalid" : "")}/>
                    <ErrorMessage name="text_color" component="div" className="invalid-feedback"/>
                  </div>
                </fieldset>
                }

                {values.link_category === "Javascript" &&
                <fieldset>
                  <div className="form-group">
                    <label htmlFor="button_belopp_1">Donationsbelopp (kronor)</label>
                    <span className="container p-0">
                      <span className="row align-bottom">
                        <span className="col-sm w-25">
                    <Field type="text" name="button_belopp_1" maxLength={12}
                           className={"form-control" + (errors.button_belopp_1 && touched.button_belopp_1 ? " is-invalid" : "")}/>
                    <ErrorMessage name="button_belopp_1" component="div" className="invalid-feedback"/>
                    </span>
                        <span className="col-sm w-25">
                          {!singleButton && (
                      <Field
                        type="text" name="button_belopp_2" maxLength={12}
                             className={"form-control" + (errors.button_belopp_2 && touched.button_belopp_2 ? " is-invalid" : "")}/>
                            )}

                          <ErrorMessage name="button_belopp_2" component="div" className="invalid-feedback"/>
                        </span>
                        <span className="col-sm w-25">
                          {!singleButton && (
                          <Field
                            type="text" name="button_belopp_3" maxLength={12}
                                 className={"form-control" + (errors.button_belopp_3 && touched.button_belopp_3 ? " is-invalid" : "")}/>
                          )}

                          <ErrorMessage name="button_belopp_3" component="div" className="invalid-feedback"/>
                        </span>

                        <span className="col-sm">

                        </span>
                      </span>
                    </span>
                  </div>
                </fieldset>
                }

                <button className={["btn", "btn-primary", "mb-3", "px-5"].join(" ")} type="submit"
                        disabled={isSubmitting}>
                  Visa html för donationsknappen
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <ModalOverlay>

        </ModalOverlay>
      </ModalFocus>
    )
  }
}

export default DonationButtonCreator
