import PropTypes from "prop-types"
import React from "react"
import { User } from "react-feather"
import styled from "styled-components"
import PasswordChange from "./PasswordChange"
import DeactivateAccount from "./DeactivateAccount"

const NameContainer = styled.div`
    margin-left: 20px;
    display:inline-block;
    vertical-align: middle;
`
const IconContainer = styled.div`
    display:inline-block;
    vertical-align: middle;
`

const ButtonContainer = styled.div`
    display:inline-block;
    vertical-align: middle;
    float: right;
`

const UserInfo = ({ user_name, user_email }) => (

  <div className={["mb-3"].join(" ")}>
    <div className={[].join(" ")}>

      <div className={["justify-content-center"].join(" ")}>
        <IconContainer>
          <User size={42}/>
        </IconContainer>
        <NameContainer >
          <div className={["text-left"].join(" ")}>
            <span>Användare: </span><span>{user_name}</span>
          </div>
          <div className={["text-left"].join(" ")}>
            <span>E-post: </span><span>{user_email}</span>
          </div>
        </NameContainer>
        <ButtonContainer>
          <PasswordChange/>
          <DeactivateAccount/>
        </ButtonContainer>
      </div>
    </div>
  </div>
)

UserInfo.propTypes = {
  user_name: PropTypes.string,
  user_email: PropTypes.string,
}

UserInfo.defaultProps = {
  user_name: ``,
  user_email: ``,
}

export default UserInfo