import React, { useState } from "react"
import View from "../View"
import * as styles from "./main.module.css"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api_base_url } from "../../utils/auth"
import { Link } from "gatsby"

const axios = require('axios').default;

const Register = ({ search }) => {
  const [registrationReady, setRegistrationReady] = useState(false)

  if (registrationReady) {
    return (
      <View title="Tack för din registrering">
        <p>
          Innan du kan använda tjänsten måste du bekräfta din e-postadress. För att göra detta klickar du
          inom en timma på länken i det e-postmeddelande som har skickats till dig. Om du inte fått något
          meddelande har du troligen angett en felaktig e-postadress och kan då antingen vänta drygt en
          timma och sedan registrera dig på nytt med en korrekt e-postadress och samma användarnamn eller
          omedelbart med ett annat användarnamn. Om du klickat på länken men inte automatiskt kommit
          till inloggningsformuläret (t.ex. p.g.a. internetproblem) så kan du <Link to="/app/login">klicka här</Link>.

        </p>
      </View>
    )
  } else {

    return (
      <div className="ml-3 mr-3">
        <div className={["container-fluid"].join(" ")}>
          <div className={["row", "justify-content-center", styles.container].join(" ")}>

            <View>
              <h1>
                Välkommen till MediaLinq
              </h1>
              <div className={["text-left", "mb-4", styles.container].join(" ")}>
                <p>
                  Bli medlem i MediaLinq och registrera ditt donationskonto en
                  gång för alla. Logga sedan in och du kan fylla på ditt konto
                  (Förbetalning) med t.ex. 100 kr. Donationer och vissa artikelköp
                  kan du göra innan du fyllt på (Kredit). Betalningar kräver att
                  behållningen är tillräcklig. Insättningar på ditt MediaLinq-konto
                  kan du göra med Swish eller Bankgiro.
                </p>
                <p>
                  Genom MediaLinq kan du gilla bra artiklar, bidra till nya medias
                  utveckling och belöna skribenter och bloggare för artiklar, videofilmer
                  och poddar samt köpa artiklar, prenumerationer och vissa tjänster mm.
                </p>
                <p>
                  Mer information om MediaLinq hittar du <a href={"https://www.medialinq.se"}>här</a>.
                </p>
              </div>
              <Formik initialValues={{
                signatur: "",
                epost: "",
                pwd: ""
              }}
                      validationSchema={Yup.object().shape({
                        signatur: Yup.string().trim().lowercase()
                          .matches("^[a-zA-ZåäöÅÄÖüÜ0-9- ]+$", { message: "Användarnamnet får bara innehålla bokstäver, siffror och bindestreck", excludeEmptyString: true })
                          .required('Du måste ange ett användarnamn'),
                        epost: Yup.string()
                          .email('Ogiltig epostadress')
                          .required('Du måste ange en epostadress'),
                        pwd: Yup.string()
                          .min(6, 'Lösenordet måste innehålla minst 6 bokstäver')
                          .required('Du måste ange ett lösnord')
                      })}
                      onSubmit={(values, actions) => {

                        const options = {
                          method: 'post',
                          url: `${api_base_url()}/registration`,
                          data: {
                            username: values.signatur.toLowerCase().trim(),
                            email: values.epost.toLowerCase().trim(),
                            password: values.pwd
                          }
                        };

                        axios(options)
                          .then(response => {
                            actions.setSubmitting(false);
                              setRegistrationReady(true);
                          })
                          .catch(error => {
                            actions.setSubmitting(false);
                            if (error.response.status === 409) {
                              actions.setFieldError('signatur', "Det valda användarnamnet eller epostadressen används redan");
                            } else {
                              actions.setFieldError('signatur', "Kan inte registrera");
                            }
                          });
                      }}>
                {({ handleSubmit, errors, touched, isValidating, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}
                        className={["text-left", "font-weight-bold", styles.container].join(" ")}>
                    <div className="form-group">
                      <label htmlFor="signatur">Önskat användarnamn</label>
                      <Field type="text" name="signatur"
                             className={'form-control' + (errors.signatur && touched.signatur ? ' is-invalid' : '')}/>
                      <ErrorMessage name="signatur" component="div" className="invalid-feedback"/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="epost">E-post</label>
                      <Field type="text" name="epost"
                             className={'form-control' + (errors.epost && touched.epost ? ' is-invalid' : '')}/>
                      <ErrorMessage name="epost" component="div" className="invalid-feedback"/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="pwd">Lösenord</label>
                      <Field type="password" name="pwd"
                             className={'form-control' + (errors.pwd && touched.pwd ? ' is-invalid' : '')}/>
                      <ErrorMessage name="pwd" component="div" className="invalid-feedback"/>
                    </div>
                    <div style={{fontWeight: "normal"}}>
                      <p className={"mt-3"}>
                        Du kan som MediaLinq-medlem donera belopp från 1 kr och uppåt med endast två klick och du kan
                        donera anonymt, om du så önskar, då mottagaren bara ser ditt användarnamn och aldrig din e-post.
                        Din e-post visas däremot för mottagare av prenumerationer och köp. 
                        MediaLinq använder din e-post bara för verifiering och eventuella kontakter med dig.
                        Den lämnas aldrig ut till tredje part. När du registrerar dig
                        godkänner du våra <a href={"https://netmediasystems.se/medialinq-villkor-och-policy"}>villkor och policy</a>.
                      </p>
                      <p>
                        Du får omedelbart ett mejl med en länk som du måste klicka på inom en timme för att din
                        registrering ska accepteras. Sedan loggar du in och auktoriserar därmed den enhet du använder.
                      </p>
                    </div>
                    <button className={["btn", "btn-primary"].join(" ")} type="submit"
                            disabled={isSubmitting}>Skapa ditt konto
                    </button>
                  </Form>
                )}
              </Formik>

            </View>
          </div>
        </div>
      </div>

    )
  }
}


export default Register
