import React from "react"
import View from "./View"
import RecipientAdmin from "./RecipientAdmin"

const Admin = () => {

  const main_view = (
    <View title="MediaLinq Administration" wide={true}>

      <div className={["border", "border-dark", "p-1", "bg-light"].join(" ")}>
      <RecipientAdmin/>
      </div>
    </View>
  )


  return main_view;
}

export default Admin
