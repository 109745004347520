import React, { useState } from "react"
import { api_base_url, browserstring, getCurrentUser } from "../utils/auth"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { getAdminData } from "../utils/auth"

const axios = require("axios").default

const AdminNewRecipient = () => {
  const userdata = getCurrentUser();
  const admincookie = getAdminData();
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div className={["card", "ml-3"].join(" ")} style={{ display: isOpen ? "block" : "none" }}>
        <div className={["container", "mb-1"].join(" ")}>
          <Formik initialValues={{
            signatur: "",
            epost: "",
          }}
                  validationSchema={Yup.object().shape({
                    signatur: Yup.string().trim().lowercase()
                      .matches("^[a-zA-ZåäöÅÄÖüÜ0-9- ]+$", { message: "Användarnamnet får bara innehålla bokstäver, siffror och bindestreck", excludeEmptyString: true })
                      .required('Du måste ange ett användarnamn'),
                    epost: Yup.string()
                      .email('Ogiltig epostadress')
                      .required('Du måste ange en epostadress'),
                  })}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false)

                    const options = {
                      method: "post",
                      url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/recipient`,
                      data: {
                        new_recipient: values.signatur,
                        recipient_email: values.epost,
                        recipient_password: "",
                        BrowserData: browserstring(),
                      },
                    }

                    axios(options)
                      .then(response => {
                        actions.setSubmitting(false)
                        if (response.status !== 200) {
                          actions.setFieldError("signatur", "Kunde inte skapa mottagern")
                        } else {
                          setIsOpen(false)
                        }
                      })
                  }}
          >
            {({ errors, touched, isValidating, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="signatur">Ange mottagarens användarnamn</label>
                  <Field type="text" name="signatur"
                         className={"form-control" + (errors.signatur && touched.signatur ? " is-invalid" : "")}/>
                  <ErrorMessage name="signatur" component="div" className="invalid-feedback"/>
                </div>
                <div className="form-group">
                  <label htmlFor="epost">Ange mottagarens epostadress</label>
                  <Field type="text" name="epost"
                         className={"form-control" + (errors.epost && touched.epost ? " is-invalid" : "")}/>
                  <ErrorMessage name="epost" component="div" className="invalid-feedback"/>
                </div>
                <button className={["btn", "btn-warning", "mr-2"].join(" ")} type="submit" disabled={isSubmitting}>
                  Skapa mottagare
                </button>
                <button className={["btn", "btn-info"].join(" ")} onClick={() => {
                  setIsOpen(false)
                }}>
                  Ångra
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={["container", "mb-1"].join(" ")} style={{ display: !isOpen ? "block" : "none" }}>
        <button className={["btn", "btn-warning"].join(" ")}
                onClick={(e) => {
          setIsOpen(true)
        }}>Lägg till mottagare
        </button>
      </div>
    </div>
  )
}

export default AdminNewRecipient
