import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getAdminData, getCurrentUser, handleLoginAdmin } from "../../utils/auth"
import { XCircle } from "react-feather"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import AdminDeactivateRecipient from "../AdminDeactivateRecipient"
import View from "../View"
import { Link } from "gatsby"
import BgMaxManager from "../BgMaxManager"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {Spinner} from "react-bootstrap";

const axios = require('axios').default;

const AdminListUsers = () => {

  const [show, setShow] = useState(
    {
      account_id: null,
      username: null,
      display: false,
      action: null
    });

  const handleClose = () => setShow({
    account_id: null,
    username: null,
    display: false,
    action: null
  });
  const handleShow = (username, account_id, action) => setShow({
    account_id: account_id,
    username: username,
    display: true,
    action: action
  });

  const userdata = getCurrentUser()
  const admincookie = getAdminData()
  const [donorList, setDonorList] = useState([])
  const [donorNameSearch, setDonorNameSearch] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)

  const handleDonorNameChange = (chg) => {
    setDonorNameSearch(chg.target.value);
  }

  const handleDonorNameSearch = () => {
    const fetchDonors = (dataCallback) => {
      setShowSpinner(true);
      const options = {
        method: "post",
        url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/users`,
        data: {
          BrowserData: browserstring(),
          UserType: "Donor",
          Username: donorNameSearch
        },
      }

      axios(options)
          .then(response => {
            if (response.status === 204) {
              dataCallback([])
            } else {
              dataCallback(response.data)
            }
            setShowSpinner(false);
          })
          .catch(error => {
            setShowSpinner(false);
            if (error.response.status === 401) {
              handleLoginAdmin();
            }
          })

    }

    fetchDonors(setDonorList)
  }

  const donorNameForm = () => {
    return (<Container fluid className={"m-2"}>

            <Row><Col xs={7}>
              <input type="text" onChange={handleDonorNameChange} style={{width: "100%"}}/>
            </Col>
              <Col>
            <Button variant="primary" onClick={handleDonorNameSearch}>
              Sök
            </Button>
              </Col>
            </Row>
        </Container>
    )
  }

  const displaySearchresult = () =>
  {
    if (showSpinner) {
    return(
      <Spinner animation="border" role="status">

      </Spinner>)
    } else {
      return donorstable
    }
  }

  let donorstable = (<div></div>);
  let rows = [];

  if (donorList == null) {
    donorstable = (<div>Laddar donatorer. Detta kan ta en stund ...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  } else {
    for (let i = 0; i < donorList.length; i++) {
      if (donorList[i].Verified === "True") {
        rows.push(<tr key={donorList[i].UserName} className={styles.containerTableRow}>
          <td className={styles.containerTableCell}>
            {donorList[i].UserName}
          </td>
          <td className={styles.containerTableCell}>
            {donorList[i].Account_A.Balance.toFixed(0)}
          </td>
          <td className={styles.containerTableCell}>
            <button
              onClick={(e) => handleShow(donorList[i].UserName, donorList[i].Account_A.AccountId, "Deactivate")}>
              <XCircle size={21} color={"red"}/>
            </button>
          </td>
        </tr>);
      }

    }
  }

  donorstable = (  <table hidden={rows.length===0} className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

    <thead className={["thead-light", styles.containerTableHead].join(" ")}>
    <tr className={styles.containerTableHeadRow}>
      <th className={styles.containerTableCell}>Donator</th>
      <th className={styles.containerTableCell}>Saldo</th>
      <th aria-label="DeactivateButtons" className={styles.containerTableCell}></th>
    </tr>
    </thead>
    <tbody className={styles.containerTableBody}>
    {rows}
    </tbody>

    <Modal show={show.display} onHide={handleClose}>
      <Modal.Header closeButton>
        { show.action === "Deactivate" ? (
          <Modal.Title>Ta bort användaren {show.username}</Modal.Title>) : null }
      </Modal.Header>
      <Modal.Body>
        { show.action === "Deactivate" ? (
        <AdminDeactivateRecipient user_to_deactivate={show.username} closeCallback={handleClose}/>) : null }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </table>)

  const main_view = (
    <View title="MediaLinq Administration">

      <BgMaxManager />

      <div className={["border", "border-dark", "p-1", "bg-light"].join(" ")}>
        <p>
          Här kan du söka efter donatorer.
          </p>
        <p>För att se en
          lista med alla mottagare
          kan man <Link to="/app/admin">klicka här</Link>
        </p>
        <p>
          Sök användare genom att skriva de första bokstäverna i användarnamnet och klicka sedan på Sök.
        </p>
        {donorNameForm()}
        {displaySearchresult()}
      </div>
    </View>
  )

  return main_view;
}

export default AdminListUsers