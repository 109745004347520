import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getCurrentUser } from "../../utils/auth"
import { CreditCard, Repeat } from "react-feather"
import Moment from "react-moment"
import FileSaver from "file-saver"
import { Button } from "react-bootstrap"
import { paymentHttpColor } from "../../utils/misc"
import tippy from "tippy.js"
import Modal from "react-bootstrap/Modal"

const axios = require('axios').default;
const _ = require('lodash');

const DonationList = ({ donationList, isRecipient, refreshFunc }) => {

  const userdata = getCurrentUser();
  const [showModal, setShowModal] = useState(false);
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [totalAmountRecur, setTotalAmountRecur] = useState("")



  useEffect(() => {
    updateRecurringButtonsTooltips();
  }, [donationList])

  useEffect(() => {

      let total_amount_recurring = {};
      const options = {
        method: "post",
        url: `${api_base_url()}/${userdata.cookie}/donationhistory`,
        data: {
          BrowserData: browserstring(),
          Limit: 100,
          isRecipient: isRecipient,
          onlyRecurring: true
        },
      }

      axios(options)
        .then(response => {
          if (response.status === 200) {

            for (let i in response.data) {
              if (response.data.hasOwnProperty(i)) {
                
                if (response.data[i].recur_interval !== "") {
                  total_amount_recurring[response.data[i].currency] = _.get(total_amount_recurring, response.data[i].currency, 0) + parseInt(response.data[i].amount);
                }
              }
            }

            let amount_recur = [];
            _.forIn(total_amount_recurring, function(value, key) {
              amount_recur.push(<div key={key}>{value} {key}</div>);
            });
            setTotalAmountRecur(amount_recur);
          }
        })

  }, [isRecipient])

  const handleClose = () => {
    setSelectedDonation(null);
    setShowModal(false);
  }

  const handleShow = (donation) => {
    if (!isRecipient) {
      setSelectedDonation(donation);
      setShowModal(true);
    }
  }

  const handleCancelDonation = () => {

    const options = {
      method: "post",
      url: `${api_base_url()}/${userdata.cookie}/donation/cancel`,
      data: {
        BrowserData: browserstring(),
        timestamp: selectedDonation.timestamp,
      },
    }

    axios(options)
      .then(response => {
        setSelectedDonation(null);
        refreshFunc();
      })
      .finally(() => {
        setShowModal(false);
      })

  };


  const updateRecurringButtonsTooltips = () => {
   let textContent = "";
   if (isRecipient) {
      textContent = "Donationen upprepas automatiskt månadsvis.";
    } else {
      textContent = "Donationen upprepas automatiskt månadsvis. Klicka här för att avsluta.";
    }

    let allInstances = tippy(document.getElementsByName('RepeatDon' + (isRecipient ? "Rec": "")));

    for (const instance of allInstances) {
      instance.setContent(textContent);
    }

  }

  const downloadList = () => {
    let browser_data = browserstring();

    const options = {
      method: "get",
      // TODO!!! Inte hårdkoda!
      url: `${process.env.GATSBY_API_URL}/${userdata.cookie}/donationhistory?isRecipient=${isRecipient}&BrowserData=${browser_data}&outputFormat=csv&limit=5000`,
    }

    axios(options)
      .then(response => {
        const csvData = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

        FileSaver.saveAs(csvData, 'transactions.csv');
      });

  }

  if (donationList == null) {
    return (<div>Laddar transaktioner...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  }

  let rows = [];
  let hasUnpaidRows = false

  for (let i = 0; i < donationList.length; i++) {
    if (donationList[i].status !== 'paid') {
      hasUnpaidRows = true
    }


    rows.push( <tr key={donationList[i].timestamp} className={styles.containerTableRow}>
      <td className={styles.containerTableCell}><Moment format="YYYY-MM-DD HH:mm" date={donationList[i].timestamp} /></td>
      <td className={styles.containerTableCell}>{donationList[i].amount} {donationList[i].currency === 'SEK' ? 'kr' : donationList[i].currency}</td>
      <td style={{display: isRecipient ? "block" : "none"}} className={styles.containerTableCell}>{donationList[i].sender}</td>
      <td className={styles.containerTableCell}>{donationList[i].reference}</td>
      <td style={{display: !isRecipient ? "block" : "none"}} className={styles.containerTableCell}>
        <CreditCard color={paymentHttpColor(donationList[i].status)} size={24} />
      </td>
      <td className={styles.containerTableCell}>
        {donationList[i].recur_interval !== "" ?
          <Repeat
            name={'RepeatDon' + (isRecipient ? "Rec": "")}
            data-tippy-theme="light-border"
            strokeWidth={3}
            color='green'
            size={24}
            onClick={() => handleShow(donationList[i])}
          />
          :
          ""}
      </td>
    </tr>);
  }



  return (
    <>
    <div className={["card", "mb-2"].join(" ")}>
      <h6 style={{display: hasUnpaidRows ? "block" : "none"}}>Röd statusmarkering = avvaktar tillräcklig behållning.</h6>
            <div className={[styles.containerBody].join(" ")}>
              {rows.length === 0 ? (
                  <div>Inga transaktioner att visa</div>
                ):null}
              {rows.length > 0 ? (
              <table className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

                <thead className={["thead-light", styles.containerTableHead].join(" ")}>
                <tr className={styles.containerTableHeadRow}>
                  <th className={styles.containerTableCell}>Datum</th>
                  <th className={styles.containerTableCell}>Belopp</th>
                  <th style={{display: isRecipient ? "block" : "none"}} className={styles.containerTableCell}>Donator</th>
                  <th className={styles.containerTableCell}>Artikel</th>
                  <th style={{display: !isRecipient ? "block" : "none"}} className={styles.containerTableCell}>Status</th>
                  <th className={styles.containerTableCell}></th>
                </tr>
                </thead>
                <tbody className={styles.containerTableBody}>
                {rows}
                </tbody>
              </table>
              ):null}
            </div>

      <div className={["mb-2", "mt-2"].join(" ")}>
        <div className={["mr-4", "ml-4"].join(" ")} style={{display: totalAmountRecur.length > 0 ? "block" : "none", float: "right"}}>Aktiva månadsdonationer: {totalAmountRecur}</div>
        <Button variant="success" onClick={() => { downloadList() }} className={[styles.downloadButton, "float-right", "mr-3"].join(" ")} >Nedladdning av donationslista</Button>
      </div>

    </div>

      {!_.isEmpty(selectedDonation) &&
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Avsluta automatisk donation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Är du säker på att du vill avbryta automatiska
        donationer till {selectedDonation.reference}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Nej, jag vill fortsätta donera automatiskt
        </Button>
        <Button variant="danger" onClick={handleCancelDonation}>
          Ja, avsluta den automatiska donationen
        </Button>
      </Modal.Footer>
    </Modal>
      }
  </>
)
}

DonationList.propTypes = {
  donationList: PropTypes.array,
  isRecipient: PropTypes.bool,
  refreshFunc: PropTypes.func,
}

DonationList.defaultProps = {
  donationList: [],
}

export default DonationList