import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getAdminData, getCurrentUser, handleLoginAdmin } from "../../utils/auth"
import { Repeat, XCircle } from "react-feather"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import AdminDeactivateRecipient from "../AdminDeactivateRecipient"
import View from "../View"
import { Link } from "gatsby"
import BgMaxManager from "../BgMaxManager"
import { USER_SUBSCRIPTION_RECIPIENT } from "../../utils/constants"
import Moment from "react-moment"
import AdminDeactivateSubscription from "../AdminDeactivateSubscription"

const axios = require('axios').default;

const AdminListSubscriptions = () => {

  const [show, setShow] = useState(
    {
      site_id: null,
      username: null,
      timestamp: null,
      display: false,
      action: null
    });

  const handleClose = () => setShow({
    site_id: null,
    username: null,
    timestamp: null,
    display: false,
    action: null
  });
  const handleShow = (site_id, username, timestamp, action) => setShow({
    site_id: site_id,
    username: username,
    timestamp: timestamp,
    display: true,
    action: action
  });

  const userdata = getCurrentUser();
  const admincookie = getAdminData();
  const [siteList, setSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [subscriptionsList, setSubscriptionsList] = useState([]);


  const fetchSiteData = (dataCallback, site_id="") => {
    const options = {
      method: "post",
      url: `${api_base_url()}/${userdata.cookie}/admin/${admincookie}/subscriptions`,
      data: {
        BrowserData: browserstring(),
        SiteId: site_id
      },
    }

    axios(options)
      .then(response => {
        if (response.status === 204) {
          dataCallback([])
        } else {
          dataCallback(response.data);
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          handleLoginAdmin();
        }
      })

  }

  useEffect(() => {

    fetchSiteData(setSiteList)
  }, [])

  const selectSite = (event) => {

    fetchSiteData(setSubscriptionsList, event.target.value);

  }

  let site_buttons = [];

  if (siteList != null) {
    for (let site of siteList) {
      site_buttons.push(
        <Button key={site.siteId} variant="secondary" onClick={selectSite} value={site.siteId}>
          {site.siteName}
        </Button>
      )
    }
  }

  let rows = [];
  let hasUnpaidRows = false
  for (let i = 0; i < subscriptionsList.length; i++) {
    if (subscriptionsList[i].status !== 'paid') {
      hasUnpaidRows = true
    }

    const siteId = subscriptionsList[i].siteId + "~~~" + subscriptionsList[i].user;

    rows.push(
      <tr key={subscriptionsList[i].timestamp} className={styles.containerTableRow}>
        <td className={styles.containerTableCell}>
          <Moment format="YYYY-MM-DD HH:mm" date={subscriptionsList[i].validFrom} />
        </td>
        <td className={styles.containerTableCell}>
          <Moment format="YYYY-MM-DD HH:mm" date={subscriptionsList[i].validTo} />
        </td>
        <td className={styles.containerTableCell}>
          {subscriptionsList[i].user}
        </td>
        <td className={styles.containerTableCell}>
          {subscriptionsList[i].subscriptionType} - {subscriptionsList[i].subscriptionPackage}
        </td>
        <td className={styles.containerTableCell}>
          {subscriptionsList[i].amount} {subscriptionsList[i].currency === 'SEK' ? 'kr' : subscriptionsList[i].currency}
        </td>
        <td className={styles.containerTableCell}>
          <Repeat
            name={'Repeat'}
            data-tippy-theme="light-border"
            className={['ml-2', 'mr-2'].join(" ")}
            strokeWidth={3}
            color={subscriptionsList[i].recurring ? 'green' : 'red'}
            size={16}/>
        </td>
        <td className={styles.containerTableCell}>
          {subscriptionsList[i].pageUrl}
        </td>
        <td className={styles.containerTableCell}>
            <button
              onClick={(e) => handleShow(subscriptionsList[i].siteId , subscriptionsList[i].user, subscriptionsList[i].timestamp, "Deactivate")}>
              <XCircle size={21} color={"red"}/>
            </button>
          </td>
      </tr>
    );
  }


  const main_view = (
    <View title="MediaLinq Administration" wide={true}>

      <div className={["border", "border-dark", "p-1", "bg-light"].join(" ")}>
        <p>
          Här visas en lista med alla prenumerationer. Börja med att välja en sajt i listan.
          </p>
        <p>För att se en
          lista med alla mottagare
          kan man <Link to="/app/admin">klicka här</Link>
        </p>
        <div>
          {site_buttons}
        </div>

        <div>
          <h4>Senaste prenumerationer.</h4>
          <div className={[styles.containerBody].join(" ")}>
            {rows.length === 0 ? (
              <div>Inga prenumerationer att visa</div>
            ):null}
            {rows.length > 0 ? (
              <table className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

                <thead className={["thead-light", styles.containerTableHead].join(" ")}>
                <tr className={styles.containerTableHeadRow}>
                  <th className={styles.containerTableCell}>Startdatum</th>
                  <th className={styles.containerTableCell}>Slutdatum</th>
                  <th className={styles.containerTableCell}>Prenumerant</th>
                  <th className={styles.containerTableCell}>Prenumerationstyp</th>
                  <th className={styles.containerTableCell}>Belopp</th>
                  <th className={styles.containerTableCell}></th>
                  <th className={styles.containerTableCell}>Sida</th>
                  <th className={styles.containerTableCell}></th>
                </tr>
                </thead>
                <tbody className={styles.containerTableBody}>
                {rows}
                </tbody>

                <Modal show={show.display} onHide={handleClose}>
                  <Modal.Header closeButton>
                    { show.action === "Deactivate" ? (
                      <Modal.Title>Inaktivera prenumerationen</Modal.Title>) : null }
                  </Modal.Header>
                  <Modal.Body>
                    { show.action === "Deactivate" ? (
                      <AdminDeactivateSubscription user={show.username} site={show.site_id} timestamp={show.timestamp} closeCallback={handleClose}/>) : null }
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

              </table>
            ):null}
          </div>
        </div>

      </div>
    </View>
  )

  return main_view;
}

export default AdminListSubscriptions