import React, { useState } from "react"
import { api_base_url, browserstring, getCurrentUser, logout } from "../utils/auth"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { navigate } from "@reach/router"
import styled from "styled-components"

const axios = require("axios").default

const ThickBorderedDiv = styled.div`
  border-width: 3px !important;
`

const DeactivateAccount = () => {
  const { username, cookie } = getCurrentUser()
  const [isOpen, setIsOpen] = useState(false)


  return (
    <div>
      <div style={{display: isOpen ? "block" : "none", margin: "auto", position: "absolute", left: "50%", top: "0"}}>
      <ThickBorderedDiv className={["card", "ml-3", "border", "border-danger"].join(" ")} style={{ zIndex: "301"  }}>
        <div className={["container", "mb-1"].join(" ")}>
          <Formik initialValues={{
            username: username,
            username_repeat: "",
          }}
                  validationSchema={Yup.object().shape({
                    username_repeat: Yup.string()
                      .oneOf([Yup.ref("username"), null], "Användarnamnet matchar inte")
                      .required("Du måste ange ditt användarnamn"),
                  })}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false)

                    const options = {
                      method: "post",
                      url: `${api_base_url()}/${cookie}/profile/deactivate`,
                      data: {
                        BrowserData: browserstring(),
                      },
                    }

                    axios(options)
                      .then(response => {
                        actions.setSubmitting(false)
                        if (response.status !== 200) {
                          actions.setFieldError("username_repeat", "Kunde inte avsluta kontot. Kontakta systemadministratören.")
                        } else {
                          setIsOpen(false)
                          logout(() => navigate(`/app/login`))
                        }
                      })
                  }}
          >
            {({ errors, touched, isValidating, isSubmitting }) => (
              <Form>
                <input type="hidden" name="username" />
                <div className={["font-weight-bold"].join(" ")}>
                  Är du säker på att du vill avsluta ditt konto? Detta kan sedan inte ångras. Eventuell återstående
                  behållning på ditt konto kommer att tillfalla MediaLinq.
                </div>
                <div className="form-group">
                  <label htmlFor="username_repeat">Som säkerhetsåtgärd måste du nedan ange ditt användarnamn</label>
                  <Field type="text" name="username_repeat"
                         className={"form-control" + (errors.username_repeat && touched.username_repeat ? " is-invalid" : "")}/>
                  <ErrorMessage name="username_repeat" component="div" className="invalid-feedback"/>
                </div>
                <button className={["btn", "btn-danger", "mr-2"].join(" ")} type="submit" disabled={isSubmitting}>
                  Avsluta kontot
                </button>
                <button className={["btn", "btn-info"].join(" ")} onClick={() => {
                  setIsOpen(false)
                }}>
                  Ångra
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </ThickBorderedDiv>
        <div style={{position: "fixed", zIndex: "300", backgroundColor: "rgba(23, 23, 23, 0.7)", top: "0", left: "0", height: "100vh", width: "100vw"}}>

      </div>
      </div>
      <div className={["container", "mb-1"].join(" ")} style={{ display: !isOpen ? "block" : "none" }}>
        <button className={["btn", "btn-danger", "btn-sm", "py-0"].join(" ")} style={{ fontSize: "0.8em"}}
                onClick={(e) => {
          setIsOpen(true)
        }}>Avsluta kontot
        </button>
      </div>
    </div>
  )
}

export default DeactivateAccount
