import React from "react"
import Layout from "../components/Layout"
import Donation from "../components/Donation"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import withLocation from "../components/withLocation"
import { Router } from "@reach/router"
import withJWT from "../components/withJWT"
import { VISITOR_DONATION } from "../utils/constants"

const Donera = (props) => {

  const query = new URLSearchParams(props.location.search)
  const s = query.get("s");

  return (
    <Layout>
      <Status {...props} menuoptions={{ logout: false,
        close: s !== "referrer",
        returnToDonation: false,
        visitorType: VISITOR_DONATION
      }} />
      <Router>
        <PrivateRoute path="/donera" component={withLocation(Donation)} />
        <Login path="/app/login"/>
      </Router>
    </Layout>
  )
}

export default withJWT(Donera)
