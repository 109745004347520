import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import { api_base_url, browserstring, getAdminData, getCurrentUser, handleLoginAdmin } from "../../utils/auth"
import { DollarSign, XCircle } from "react-feather"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import PerformPayout from "../PerformPayout"
import AdminDeactivateRecipient from "../AdminDeactivateRecipient"
import { Link } from "gatsby"
import AdminNewRecipient from "../AdminNewRecipient"

const axios = require('axios').default;

const RecipientAdmin = () => {
  const [show, setShow] = useState(
    {
      account_id_b: null,
      account_id_c: null,
      account_id_d: null,
      username: null,
      display: false,
      action: null
    });

  const handleClose = () => setShow({
    account_id_b: null,
    account_id_c: null,
    account_id_d: null,
    username: null,
    display: false,
    action: null
  });
  const handleShow = (username, account_id_b, account_id_c, account_id_d, action) => setShow({
    account_id_b: account_id_b,
    account_id_c: account_id_c,
    account_id_d: account_id_d,
    username: username,
    display: true,
    action: action
  });

  const userdata = getCurrentUser()
  const admincookie = getAdminData()
  const [recipientList, setRecipientList] = useState([])

  useEffect(() => {
    const fetchRecipients = (dataCallback, usercookie) => {
      const options = {
        method: "post",
        url: `${api_base_url()}/${usercookie}/admin/${admincookie}/users`,
        data: {
          BrowserData: browserstring(),
          UserType: "AllRecipient"
        },
      }

      axios(options)
        .then(response => {
          if (response.status === 204) {
            dataCallback([])
          } else {
            dataCallback(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            handleLoginAdmin();
          }
        })

    }

    fetchRecipients(setRecipientList, userdata.cookie)
  }, [])


  let recipientstable = (<div></div>);
  let rows = [];

  if (recipientList == null) {
    recipientstable = (<div>Laddar mottagare...
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div></div>)
  } else {
    for (let i = 0; i < recipientList.length; i++) {
      if (recipientList[i].Verified === "True") {
        rows.push(<tr key={recipientList[i].UserName} className={styles.containerTableRow}>
          <td className={styles.containerTableCell}>
            {recipientList[i].UserName}
          </td>
          <td className={styles.containerTableCell}>
            {('Account_B' in recipientList[i]) ? recipientList[i].Account_B.Balance.toFixed(0) : "-"}
          </td>
          <td className={styles.containerTableCell}>
            {('Account_C' in recipientList[i]) ? recipientList[i].Account_C.Balance.toFixed(0) : "-"}
          </td>
          <td className={styles.containerTableCell}>
            {('Account_D' in recipientList[i]) ? recipientList[i].Account_D.Balance.toFixed(0) : "-"}
          </td>
          <td className={styles.containerTableCell}>
            <button onClick={(e) => handleShow(
              recipientList[i].UserName,
              ('Account_B' in recipientList[i]) ? recipientList[i].Account_B.AccountId : "",
              ('Account_C' in recipientList[i]) ? recipientList[i].Account_C.AccountId : "",
              ('Account_D' in recipientList[i]) ? recipientList[i].Account_D.AccountId : "",
              "Payout")}>
              <DollarSign size={21} color={"blue"}/>
            </button>
          </td>
          <td className={styles.containerTableCell}>
            <button
              onClick={(e) => handleShow(
                recipientList[i].UserName,
                ('Account_B' in recipientList[i]) ? recipientList[i].Account_B.AccountId : "",
                ('Account_C' in recipientList[i]) ? recipientList[i].Account_C.AccountId : "",
                ('Account_D' in recipientList[i]) ? recipientList[i].Account_D.AccountId : "",
                "Deactivate")}>
              <XCircle size={21} color={"red"}/>
            </button>
          </td>
        </tr>);
      }

    }
  }

  recipientstable = (  <table className={["table", "table-striped", "mb-0", styles.containerTable].join(" ")}>

    <thead className={["thead-light", styles.containerTableHead].join(" ")}>
    <tr className={styles.containerTableHeadRow}>
      <th className={styles.containerTableCell}>Mottagare</th>
      <th className={styles.containerTableCell}>Saldo - Donation</th>
      <th className={styles.containerTableCell}>Saldo - Prenumeration</th>
      <th className={styles.containerTableCell}>Saldo - Betalningar</th>
      <th aria-label="PayoutButtons" className={styles.containerTableCell}></th>
      <th aria-label="DeactivateButtons" className={styles.containerTableCell}></th>
    </tr>
    </thead>
    <tbody className={styles.containerTableBody}>
    {rows}
    </tbody>

    <Modal show={show.display} onHide={handleClose}>
      <Modal.Header closeButton>
        { show.action === "Payout" ? (
        <Modal.Title>Utbetalning till {show.username}</Modal.Title>) : null }
        { show.action === "Deactivate" ? (
          <Modal.Title>Ta bort användaren {show.username}</Modal.Title>) : null }
      </Modal.Header>
      <Modal.Body>
        { show.action === "Payout" ? (
        <PerformPayout closeCallback={handleClose} account_id_b={show.account_id_b} account_id_c={show.account_id_c} account_id_d={show.account_id_d}/> ) : null }
        { show.action === "Deactivate" ? (
        <AdminDeactivateRecipient user_to_deactivate={show.username} closeCallback={handleClose}/>) : null }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </table>)

  const main_view = (
      <div className={["border", "border-dark", "p-1", "bg-light"].join(" ")}>
        <p>
        Här visas en lista med alla mottagare.
          </p><p>För att se en lista med alla donatorer
        kan man <Link to="/app/admin/users">klicka här</Link>
        </p>
        {recipientstable}
        <AdminNewRecipient />
      </div>
  )

  return main_view;
}

export default RecipientAdmin