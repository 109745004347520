import React, { useEffect, useState } from "react"
import * as styles from "./main.module.css"
import SwishPayment from "./swish"
import { navigate } from "gatsby"
import payment from "./payment.png"
import BgPayment from "./bankgiro"
import Tab from 'react-bootstrap/Tab'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import withUserData from "../withUserData"
import Alert from "react-bootstrap/Alert"
import Container from "react-bootstrap/Container"
import { Accordion, Card } from "react-bootstrap"

const Refill = (props) => {

  const [userAllowedToRefill, setUserAllowedToRefill] = useState(true);

  useEffect(() => {
    // Only active and confirmed users are allowed to refill
    props.checkUserStatusFunc().then(async response => {
      setUserAllowedToRefill(response === 1);
    });
  }, [props]);

  const user_alert_element = () => {
    if (!userAllowedToRefill) {
      return(
        <div className={"col"}>
        <Alert key="errormess" variant="danger">
        <Alert.Heading>Påfyllning av konto inte möjlig</Alert.Heading>
        <p>
          För att kunna fylla på ditt konto måste du först bekräfta din epostadress genom att klicka
          på länken i det e-postmeddelande som skickades till din epostadress i samband med att
          du registrerade ditt konto på MediaLinq.
        </p>
          <p>
            Kontrollera ditt spam-filter om du inte har fått e-postmeddelandet.
          </p>
      </Alert>
        </div>
      );
    }
  }

  return (
    <Container  >
      <Row>

      {user_alert_element()}

      </Row>
      <Row>
        <Col>
            <Card>
            <Card.Header style={{padding: "10px 10px"}}>
            <img alt="" src={payment} className="mx-auto card-img-top w-50"/>
            </Card.Header>
              <Card.Body>
            <div>
              Välj betalmetod i menyn nedan.
            </div>
            <div className="card-body">
              <Tab.Container id="left-tabs-example" defaultActiveKey="swish">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="swish">Swish</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="bankgiro">Bankgiro</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link onClick={() => {navigate(-1)}}>Återgå</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="swish">
                        <div className={styles.refillcontainer}>
                          <SwishPayment enabled={userAllowedToRefill} />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="bankgiro">
                        <div className={styles.refillcontainer}>
                          <BgPayment enabled={userAllowedToRefill} account={props.userData.Account_A}/>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

          </div>
              </Card.Body>
          </Card>

        </Col>
      </Row>
    </Container>
  )
}

export default withUserData(Refill)